
import { api } from "@/api";
import { IDatasetCreate, IDatasetUpdate } from "@/interfaces";
import { dispatchGetDatasets } from "@/store/dataset/actions";
import { readDatasets } from "@/store/dataset/getters";
import { readToken, readWorkspace, readHasAdminAccess } from "@/store/main/getters";
import { Component, Vue } from "vue-property-decorator";

import FileDrop from "@/components/FileDrop.vue";

@Component({
  components: {
    FileDrop,
  },
})
export default class DatasetUpload extends Vue {
  // redirect params stored
  public redirected: any = false;
  public e6: number = 1;
  public panel = [true, true, true, true, true];
  public chat = false;
  public replies = false;
  public ignoreSubject = false;
  public onlySat = false;
  public responseTime = false;
  public onlyClosedSolved = false;
  public aggregateReplies = true;
  public dialog: boolean = true;
  public loading: boolean = false;
  public name: string = "";
  public description: string = "";
  public file: any = null;
  public headDialog: boolean = false;
  public upload = false;
  public uploadError: any = null;
  public loadingProgress: number | null = null;
  public selectedTimespan: string = "1.yr";
  public isTranscription: boolean = false;

  public uploadType: string = "csv";

  public playStoreApps: any[] = [];
  public loadingPreview: boolean = false;
  public pickedSurvey: string = "";
  public appStoreApps: any[] = [];
  public searchTerm: string = "";
  public playStoreUpload = false;
  public appStoreUpload = false;
  public pickedAppstoreApp: any = null;
  public pickedPlayApp: any = null;
  public months: string[] = ["2022-09", "2022-10"];
  public previousMonths: string[] = ["2022-09", "2022-10"];
  public sitesOrCategory: string = "";
  public running: boolean = false;
  public allReplies: boolean = true;
  public convos: boolean = false;
  public advancedImport: boolean = false;
  public adminReplies: boolean = false;
  public userReplies: boolean = true;
  public maxDatapoints: number = 100000;
  public connect: boolean = false;
  public modelConnect: any = null;
  public toggleHeader: boolean = true;

  public loadingButton: boolean = false;

  public recommendation: number = 0;
  public filename: string = "";
  public columns: any[] = [];
  public rows: any[] = [];

  get isAdmin() {
    return readHasAdminAccess;
  }

  get tableDataHeader() {
    const rows: any[] = [];
    if (this.toggleHeader) {
      this.columns.forEach((column, key) => {
        const text = "";
        rows.push({
          text: this.rows[0][column.value] + text,
          value: column.value,
          align: "left",
        });
      });
    } else {
      this.columns.forEach((column, key) => {
        const text = "";
        rows.push({
          text: column.value + text,
          value: column.value,
          align: "left",
        });
      });
    }

    return rows;
  }

  get previewRows() {
    if (this.toggleHeader) {
      return this.rows.slice(1);
    }
    return this.rows;
  }

  get getRecommendation() {
    if (typeof this.recommendation !== "undefined") {
      return this.recommendation;
    } else {
      return 0;
    }
  }

  get activeRecommendation() {
    return this.columns[this.recommendation].value;
  }

  public highlight(key, value) {
    let newColumn = this.recommendation;
    if (Number(key) || Number(key) === 0) {
      newColumn = Number(key);
    }
    this.recommendation = newColumn;
  }

  get workspace() {
    return readWorkspace(this.$store);
  }

  public async mounted() {
    await dispatchGetDatasets(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });

    // store params if we were redirected
    if (this.$route.query.model === "true") {
      this.redirected = true;
    }
    if (this.$route.query.connect === "true") {
      this.connect = true;
      const tmp: any = this.$route.query.model_id;
      this.modelConnect = parseInt(tmp, 10);
      console.log("modelConnect", this.modelConnect);
    }
  }

  get datasets() {
    return readDatasets(this.$store);
  }

  get nameExists(): boolean {
    if (this.datasets?.length) {
      return this.datasets.filter((dataset) => dataset.name === this.name).length > 0;
    } else {
      return false;
    }
  }

  get nameErrors(): string[] {
    if (this.nameExists) {
      return ["This name does already exist"];
    }
    if (this.name.length && (this.name.length < 1 || this.name.length > 40)) {
      return ["Please provide a name between 1 and 40 characters long"];
    }
    return [];
  }

  get fileIsClean(): boolean {
    if (!this.file) {
      return false;
    }
    return true;
  }
  get isClean(): boolean {
    if (this.name.length < 1 || this.name.length > 40) {
      return false;
    }
    // if (this.description.length < 1 || this.description.length > 240) {
    //   return false;
    // }
    if (this.nameExists) {
      return false;
    }

    return true;
  }

  get token() {
    return readToken(this.$store);
  }

  public onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    this.loadingProgress = percentCompleted;
  }

  public pusher(streaming: boolean, datasetId: any) {
    if (this.redirected === true) {
      if (this.connect === true) {
        this.$router.push(
          `/main/${this.$router.currentRoute.params.workspaceid}/classification/upload?redirected=true&dataset_id=${datasetId}&trustpilot=${streaming}&connect=true&model_id=${this.modelConnect}`,
        );
      } else {
        this.$router.push(
          `/main/${this.$router.currentRoute.params.workspaceid}/classification/upload?redirected=true&dataset_id=${datasetId}&trustpilot=${streaming}`,
        );
      }
    } else {
      this.$router.push(`/main/${this.$router.currentRoute.params.workspaceid}/datasets/view`);
    }
  }

  public async uploadDataset() {
    this.loading = true;
    this.uploadError = null;
    // this.dialog = false;

    const config = {
      onUploadProgress: this.onUploadProgress,
    };

    await api
      .uploadDataset(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.file,
        config,
      )
      .then((r) => {
        this.loading = false;
        this.uploadError = null;
        this.loadingProgress = null;
        this.file = null;
        this.recommendation = r.data.recommendation;
        this.columns = r.data.columns;
        this.rows = r.data.rows;
        this.filename = r.data.filename;
        this.e6 = 2;
      })
      .catch((uploadError) => {
        console.log("UploadError", uploadError.response);
        this.loading = false;
        this.loadingProgress = null;
        this.file = null;
        this.uploadError = uploadError.response;
      });
  }

  public async createDataset() {
    this.loading = true;
    this.uploadError = null;
    // this.dialog = false;

    const datasetInfo: IDatasetCreate = {
      name: this.name,
      description: this.description,
      filename: this.filename,
      text_column_id: this.recommendation,
    };

    await api
      .createDataset(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        datasetInfo,
        this.toggleHeader,
        this.isTranscription,
      )
      .then((r) => {
        this.loading = false;
        this.name = "";
        this.description = "";
        this.pusher(false, null);
      })
      .catch((uploadError) => {
        console.log("UploadError", uploadError.response);
        this.loading = false;
        this.uploadError = uploadError.response;
      });
  }

  public async goBack() {
    if (this.e6 === 2) {
      // go back to step 1 (upload dataset)
      this.columns = [];
      this.rows = [];
      this.recommendation = 0;
      this.e6 = 1;
      this.toggleHeader = true;
      this.uploadError = null;
      this.name = "";
      this.description = "";
    }
  }

  public logFiles(fileList: FileList) {
    this.uploadError = null;
    this.file = fileList[0];
    if (this.file) {
      this.setName(this.file.name);
    } else {
      this.setName("");
    }
  }

  public setName(fileName) {
    try {
      if (fileName === "") {
        this.name = "";
      } else {
        const name = fileName;
        const nameArray = name.split(".");
        this.name = nameArray[0] + " dataset";
      }
    } catch {
      this.name = "";
    }
  }
}
