
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { IDataset, IDatasetCreate } from "@/interfaces";
import { readDatasets, readDataset } from "@/store/dataset/getters";

import { readModels } from "@/store/model/getters";

import {
  dispatchGetDatasets,
  dispatchCreateDataset,
  dispatchDeleteDataset,
} from "@/store/dataset/actions";

import { dispatchGetModels } from "@/store/model/actions";
import dayjs from "dayjs";

import { api } from "@/api";

import { readWorkspace, readToken } from "@/store/main/getters";
import FileDrop from "@/components/FileDrop.vue";
import DatasetPageCard from "@/components/DatasetPageCard.vue";

@Component
export default class AnalyticsView extends Vue {

  public connectedModels: any = [];
  public pickedDatasetId: number = 0;
  public mini: boolean = true;
  public drawer: boolean = true;
  public loading: boolean = false;

  get token() {
    return readToken(this.$store);
  }

  public async mounted() {
        this.loading = true;
    await dispatchGetDatasets(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
    await this.getAll();

  }

  public getDatasetName(id) {
    const dataset = this.datasets.find((dataset) => dataset.id === id);
    return dataset ? dataset.name : "";
  }

  public sortConnectedModels() {
    this.connectedModels.sort((a, b) => {
      // If both models have the same status, sort by id
      if (a.model.status === b.model.status) {
        return b.model_id - a.model_id;
      }
      // If model a is "deployed", it should come first
      if (a.model.status === "deployed") {
        return -1;
      }
      // If model b is "deployed", it should come first
      if (b.model.status === "deployed") {
        return 1;
      }
      // If neither model is "deployed", sort by id
      return b.model_id - a.model_id;
    });
  }

  public async getAll() {
    this.pickedDatasetId = 0;
    this.connectedModels = [];

    await this.getAllConnectedModelsWithLinks();
    this.sortConnectedModels();
  }

  public async fetchModels() {
    await dispatchGetModels(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
  }

  get models() {
    return readModels(this.$store);
  }


  public async fetchDatasets() {
  }

  public async getAllConnectedModelsWithLinks() {
    this.loading = true;
    let allConnectedModels: any = [];
    await api
      .getAllConnectedModelsWithLinks(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
      )
      .then((r) => {
        allConnectedModels = r.data;
        this.loading = false;
      })
      .catch((error) => {
        console.log("error when getting chosen dataset", error);
        this.loading = false;
      });
    this.connectedModels = allConnectedModels.filter((model) => model.model.label_containers[0].type === "single");
  }

  public async getDatasetConnectedModelsWithLinks() {
    console.log("Getting models from dataset", this.pickedDatasetId)
    let allConnectedModels: any = [];
    await api
      .getDatasetConnectedModelsWithLinks(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.pickedDatasetId,
      )
      .then((r) => {
        allConnectedModels = r.data;
      })
      .catch((error) => {
        console.log("error when getting chosen dataset", error);
      });
    this.connectedModels = allConnectedModels.filter((model) => model.model.label_containers[0].type === "single");
    this.sortConnectedModels();

  }

  public datasetById(id) {
    return readDataset(this.$store)(+id);
  }

  public hasProperty(specialColumnValue: string, dataset: any) {
    let hasValue = false;

    for (const key in dataset!.meta_data.columns) {
      if (dataset!.meta_data.columns.hasOwnProperty(key)) {
        const column = dataset!.meta_data.columns[key];
        if (column.special_column === specialColumnValue) {
          hasValue = true;
          break;
        }
      }
    }
    return hasValue;
  }


  public async launchGraphCheck(modelId: number, datasetId: number) {
    // let connection = this.connectedModels.filter((connection) => connection.model_id === modelId)[0];
    // console.log(connection)
    const dataset = await this.datasetById(datasetId);

    if (this.hasProperty("date", dataset) && this.hasProperty("response_time", dataset) && this.hasProperty("resolve_time", dataset)) {
      this.$router.push(`/main/${this.$router.currentRoute.params.workspaceid}/datasets/${datasetId}/dashboard/graphs/model/${modelId}/time`);
    } else if (this.hasProperty("date", dataset) && this.hasProperty("csat", dataset)) {
      this.$router.push(`/main/${this.$router.currentRoute.params.workspaceid}/datasets/${datasetId}/dashboard/graphs/model/${modelId}/csat`);
    } else {
      this.$router.push(`/main/${this.$router.currentRoute.params.workspaceid}/datasets/${datasetId}/dashboard/graphs/model/${modelId}/setup?setup_type=csat&redirect=graphs`);
      console.log("none");
    }
  }

  get workspace() {
    return readWorkspace(this.$store);
  }

  public dateParse(ts) {
    return dayjs(ts).format("MMMM D, YYYY h:mm A");
  }

  public formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  get datasets() {
    const unsortedDatasets = readDatasets(this.$store);
    return unsortedDatasets.sort((a, b) => {
      return b.id - a.id;
    });
  }

}
