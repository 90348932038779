
import Vue from "vue";
import { Component, Emit } from "vue-property-decorator";

@Component
export default class FileDrop extends Vue {
  // internal properties
  public formUpload: boolean = false;
  public dragover: boolean = false;
  public file: any = null;
  // fileupload: HTMLElement = new HTMLElement();

  public mounted() {
    // to register listeners, we have to know the
    // html elements
    const dropzone = this.$el;
    const fileupload = this.$el.firstElementChild as HTMLElement;
    // register listeners on your dropzone / v-sheet
    if (dropzone) {
      // register all drag & drop event listeners
      dropzone.addEventListener("dragenter", (e) => {
        e.preventDefault();
        this.dragover = true;
      });
      dropzone.addEventListener("dragleave", (e) => {
        e.preventDefault();
        this.dragover = false;
      });
      dropzone.addEventListener("dragover", (e) => {
        e.preventDefault();
        this.dragover = true;
      });
      dropzone.addEventListener("drop", (e) => {
        e.preventDefault();
        const dragevent = e as DragEvent;
        if (dragevent.dataTransfer) {
          this.filesSelected(dragevent.dataTransfer.files);
        }
      });

      dropzone.addEventListener("keypress", (e) => {
        e.preventDefault();
        const keyEvent = e as KeyboardEvent;
        if (keyEvent.key === "Enter") {
          if (fileupload) {
            fileupload.click();
          }
        }
      });
      // register listeners on the file input
      if (fileupload) {
        fileupload.addEventListener("change", (e) => {
          const target = e.target as HTMLInputElement;
          if (target.files) {
            this.filesSelected(target.files);
          }
        });
      }
    }
  }
  public clickfileupload() {
    const fileupload = this.$el.firstElementChild as HTMLElement;
    fileupload.click();
  }
  /**
   * upload event...
   */
  @Emit()
  public filesSelected(fileList: FileList) {
    this.dragover = false;
    this.file = fileList[0];
  }
}
