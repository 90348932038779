
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { IDataset, IDatasetCreate, IEmbeddedDashboardCreate } from "@/interfaces";
import { readDatasets, readDataset } from "@/store/dataset/getters";

import { readModels } from "@/store/model/getters";

import {
  dispatchGetDatasets,
  dispatchCreateDataset,
  dispatchDeleteDataset,
} from "@/store/dataset/actions";

import { dispatchGetModels } from "@/store/model/actions";
import dayjs from "dayjs";

import { api } from "@/api";
import { appName, supersetUrl } from "@/env";
import { readWorkspace, readToken } from "@/store/main/getters";
import FileDrop from "@/components/FileDrop.vue";
import DatasetPageCard from "@/components/DatasetPageCard.vue";
import { parse } from "path";

@Component
export default class AnalyticsView extends Vue {
  public embeddedDashboards: any = [];
  public unpublishedDashboards: any = [];
  public mini: boolean = true;
  public drawer: boolean = true;
  public loading: boolean = false;
  public publish: boolean = false;
  public publishDialog: boolean = false;
  public name: string = "";
  public description: string = "";
  public pickedId: number = 0;
  public rowsPerPageItems: number[] = [4, 8, 12];
  public pagination: any = {
    rowsPerPage: 4,
  };
  public unpublishedError: any = null;
  public publishError: any = null;

  get token() {
    return readToken(this.$store);
  }

  public routeTo(id: number) {
    this.$router.push(
      `/main/${this.$router.currentRoute.params.workspaceid}/analytics/embed/${id}`,
    );
  }

  public async unpublishEmbeddedDashboard(id: number) {
    try {
      const response = await api.unpublishEmbeddedDashboard(this.token, this.workspace!.id, id);
      await this.getAllEmbeddedDashboards();
      await this.getAllUnpublishedDahsboards();
    } catch (error) {
      console.log(error);
    }
  }

  public closeUnpublished() {
    this.getAllUnpublishedDahsboards();
    this.publish = false;
  }

  public async mounted() {
    await this.getAllEmbeddedDashboards();
    await this.getAllUnpublishedDahsboards();
  }

  public routeToNameing(name: string, id: number) {
    this.name = name;
    this.publishDialog = true;
    this.pickedId = id;
  }

  public closePublishDialog() {
    this.publishDialog = false;
    this.name = "";
    this.description = "";
    this.pickedId = 0;
    this.publishError = null;
  }

  public async submitPublish() {
    const publishForm = this.$refs.publishForm as Vue & { validate: () => boolean };

    if (publishForm !== undefined && publishForm.validate()) {
      // Proceed with form submission logic
      console.log("ok");
      await this.embeddDashboard();
      await this.getAllEmbeddedDashboards();
      await this.getAllUnpublishedDahsboards();
      this.publish = false;
    }
  }

  public async embeddDashboard() {
    this.loading = true;
    this.publishError = null;
    try {
      const obj: IEmbeddedDashboardCreate = {
        name: this.name,
        description: this.description,
        superset_id: this.pickedId,
        workspace_id: this.workspace!.id,
      };
      const res = await api.embedDashboard(
        this.token,
        parseInt(this.$route.params.workspaceid),
        obj,
      );
      this.loading = false;
      this.closePublishDialog();
    } catch (error) {
      // Check if the error is an Axios error
      if (error instanceof Error && "response" in error) {
        this.publishError = (error as any).response;
      } else {
        // Handle other types of errors (optional)
        this.publishError = "An unexpected error occurred";
      }

      this.loading = false;
      console.error("Could not publish dashoard", error);
    }
  }

  public async getAllEmbeddedDashboards() {
    const res = await api.getEmbeddedDashboards(
      this.token,
      parseInt(this.$route.params.workspaceid),
    );
    this.embeddedDashboards = res.data;
  }

  public async getAllUnpublishedDahsboards() {
    try {
      const res = await api.getUnpublishedDahsboards(
        this.token,
        parseInt(this.$route.params.workspaceid),
      );
      this.unpublishedDashboards = res.data;
    } catch (error) {
      console.error("Error getting unpublished dashboards:", error);
      // Check if the error is an Axios error
      if (error instanceof Error && "response" in error) {
        this.unpublishedError = (error as any).response;
      } else {
        // Handle other types of errors (optional)
        this.unpublishedError = "An unexpected error occurred";
      }
    }
  }

  get workspace() {
    return readWorkspace(this.$store);
  }

  public dateParse(ts) {
    return dayjs(ts).format("MMMM D, YYYY h:mm A");
  }

  public formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  openDashboardLink() {
    window.open(supersetUrl, "_blank");
  }
}
