

import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { IModel } from '@/interfaces';

@Component
export default class DropdownMenu extends Vue {
  @Prop({ type: String, default: 'Select' }) placeholder!: string;
  @Prop({ type: Array, required: true }) options!: IModel[];
  @Prop({ type: String, required: true }) menuId!: string;

  @Emit('item-clicked')
  clicked(item: IModel) {
    console.log(item.status)
    return { menuId: this.menuId, item };
  }
}
