
import { api } from "@/api";
import DatasetCard from "@/components/DatasetCard.vue";
import FileDrop from "@/components/FileDrop.vue";
import ModelCard from "@/components/ModelCard.vue";
import {
  IColumnInformation,
  IDatasetCreate,
  IModelCreate,
  IModelFilterLabel,
  IModelFilterString,
  IModelFilterTimestamp,
} from "@/interfaces";
import { dispatchGetDatasets } from "@/store/dataset/actions";
import { readDatasets } from "@/store/dataset/getters";
import { readToken } from "@/store/main/getters";
import { dispatchGetModels } from "@/store/model/actions";
import { readModels } from "@/store/model/getters";
import { Component, Vue } from "vue-property-decorator";
import { readLabelsByIds, readLabelsByName, readModel } from "@/store/model/getters";

@Component({
  components: {
    FileDrop,
    DatasetCard,
    ModelCard,
  },
})
export default class ModelUploadGenerative extends Vue {
  public dialog: boolean = true;
  public dialogRemove: boolean = false;
  public loading: boolean = false;
  public loadingButton: boolean = false; // loading => disabled buttons
  public name: any = "";
  public description: any = "";
  public algorithms: any[] = [{ title: "ORPO", headline: "algo" }];
  public chosenAlgorithm: string = "";
  public newDataset: boolean = false;
  public datasetColumns: any = { ORPO: ["chosen_response", "rejected_response", "instruction"] };
  public datasetName: string = "";
  public datasetDescription: string = "";

  public chosenDatasetId: number = -1;
  public file: any = null;
  public showGuide: boolean = false;
  public availableDatasets: any[] = [];

  public recommendationLabels: number = -1;

  public columns: any[] = [];
  public rows: any[] = [];
  public rowsToShow: any[] = [];

  public toggleMulti = false;
  public upload = false;
  public e6: number = 1;
  public BYOL: boolean = false;
  public valSet: number = 10;
  public ownData: boolean = false;

  public connectDataset: boolean = false;
  public modelConnect: number = -1;

  public uploadError: any = null;
  public loadingProgress: number | null = null;

  public dialogFilter: boolean = false;
  public d6: number = 0;
  public recommendationFilter: number = -1;
  public filterDataset: boolean = true;
  public filterError: any = null;
  public filterType: string = "must";
  public columnInformation: IColumnInformation = {} as IColumnInformation;
  public pickedValues: any[] = [];
  public showAll: boolean = false;
  public totalItems: number = 0;
  public modelFilters: any = [];
  public expandedRow: number | null = null;

  public startDate: string = "";
  public endDate: string = "";
  public menuStart: boolean = false;
  public menuEnd: boolean = false;

  public toggleNewDataset: boolean = false;
  public pickedModelAndLabels = { model: -1, labels: [] as number[], label_container: -1 };

  public connectedModels: any[] = [];
  public tempModelFilters: any = [];
  public expandedRowModel: number | null = null;

  public labelPreview: any = [];

  public previewError: any = null;

  public headersFilter = [
    { text: "Column", value: "combined" },
    { text: "Filter type", value: "filter_type" },
    { text: "Values", value: "filter_values" },
  ];

  get labelPreviewNew() {
    return this.labelPreview.filter((label) => this.labelsByName[label.name] === undefined);
  }

  get labelPreviewExisting() {
    return this.labelPreview.filter((label) => this.labelsByName[label.name] !== undefined);
  }

  get labelsByName() {
    return readLabelsByName(this.$store)(+this.modelConnect);
  }

  get allIncludedLabelsCount() {
    return this.labelPreview.filter((label) => label.include).length;
  }

  public changeIncludeByName(name: string) {
    // Loop through labelPreview
    for (let label of this.labelPreview) {
      // Check if the current label's name matches the provided name
      if (label.name === name) {
        // Toggle the include property
        label.include = !label.include;
        break; // Stop the loop if the label is found
      }
    }
  }

  public changeInclude(index: number) {
    console.log(index);
    this.labelPreview[index].include = !this.labelPreview[index].include;
  }

  public getIncludeIcon(include: boolean) {
    if (include) {
      return "check";
    } else {
      return "close";
    }
  }

  public inherit(filter: any) {
    this.tempModelFilters.push(filter);
  }

  public getLabelsById(ids: number[], modelId: number) {
    const modelo = this.connectedModels.find((m) => m.id === modelId);
    if (modelo) {
      return modelo.label_containers[0].labels.filter((l) => ids.includes(l.id));
    } else {
      return [];
    }
  }

  public getModelById(id: number) {
    return this.connectedModels.find((m) => m.id === id);
  }

  public createTempModelFilters() {
    this.tempModelFilters = this.modelFilters.slice();

    const filter: IModelFilterLabel = {
      filter_type: "label",
      label_id: this.pickedModelAndLabels.labels,
      inherited_from: this.pickedModelAndLabels.model,
      label_container: this.pickedModelAndLabels.label_container,
    };

    this.tempModelFilters.push(filter);
  }

  public doesFilterAlreadyExist(column, modelId) {
    return this.tempModelFilters.some(
      (filter: any) => filter.column_id === column && filter.inherited_from === modelId,
    );
  }

  get getModelFiltersOfPickedModel() {
    const modelo = this.connectedModels.find((m) => m.id === this.pickedModelAndLabels.model);

    // If a matching model is found, return its filters
    if (modelo) {
      console.log(modelo.filters);
      const datasetModelFilter = modelo.filters.filters.find(
        (m) => m.dataset_id === this.chosenDatasetId,
      );
      if (datasetModelFilter) {
        return datasetModelFilter.filters;
      }
      return [];
    }

    // If no matching model is found, return []
    return [];
  }

  get getModelAndLabels() {
    if (this.pickedModelAndLabels.model === -1) {
      return {
        modelName: "unknown",
        labels: [],
      };
    }

    const pickedModel = this.connectedModels.find(
      (model) => model.id === this.pickedModelAndLabels.model,
    );

    if (!pickedModel) {
      throw new Error(`Model with id ${this.pickedModelAndLabels.model} not found`);
    }

    const labels = pickedModel.label_containers
      .flatMap((container) => container.labels)
      .filter((label) => this.pickedModelAndLabels.labels.includes(label.id));

    return {
      modelName: pickedModel.name,
      labels,
    };
  }

  public pickModelAndLabel(labelId: number, modelId: number, labelContainerId: number) {
    this.pickedModelAndLabels.model = modelId;
    this.pickedModelAndLabels.label_container = labelContainerId;
  }

  public async getDatasetConnectedModels() {
    await api
      .getDatasetConnectedModels(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.chosenDatasetId,
      )
      .then((r) => {
        this.connectedModels = r.data;
      })
      .catch((error) => {
        console.log("error when getting chosen dataset", error);
      });
  }

  public createCombined(data) {
    return data.map((item) => ({
      ...item,
      combined: item.column_id ? item.column_id : item.inherited_from,
    }));
  }

  get maxAndMinDate() {
    let minDate: any = "";
    let maxDate: any = "";
    // console.log(this.columnInformation.column_values);
    if (this.columnInformation.column_values.length > 0) {
      minDate = new Date(
        Math.min(...this.columnInformation.column_values.map((date) => new Date(date).getTime())),
      )
        .toISOString()
        .slice(0, 10);
      maxDate = new Date(
        Math.max(...this.columnInformation.column_values.map((date) => new Date(date).getTime())),
      )
        .toISOString()
        .slice(0, 10);
    }
    return { minDate, maxDate };
  }

  public getFilterTypeTextify(filterType: string) {
    if (filterType === "must") {
      return "Must contain";
    } else if (filterType === "must_not") {
      return "Must not contain";
    } else if (filterType === "timestamp") {
      return "Date filter";
    } else if (filterType === "label") {
      return "Label filter";
    } else {
      return "Unknown";
    }
  }

  public checkAllowedDateStart(date) {
    const currentDate: Date = new Date(date);
    currentDate.setMonth(currentDate.getMonth() + 1);
    console.log(currentDate, this.maxAndMinDate);
    console.log(date);
    if (
      this.columnInformation.column_values.includes(date) &&
      currentDate <= new Date(this.maxAndMinDate.maxDate)
    ) {
      if (this.endDate) {
        // need at least 2 months. (+1 and > = 2 months)
        const endDateObj: Date = new Date(this.endDate);
        const currentDate: Date = new Date(date);
        currentDate.setMonth(currentDate.getMonth() + 1);
        if (endDateObj >= currentDate) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  public checkAllowedDateEnd(date) {
    const currentDate: Date = new Date(date);
    currentDate.setMonth(currentDate.getMonth() - 1);

    if (
      this.columnInformation.column_values.includes(date) &&
      currentDate >= new Date(this.maxAndMinDate.minDate)
    ) {
      if (this.startDate) {
        // need at least 2 months. (+1 and > = 2 months)
        const startDateObj: Date = new Date(this.startDate);
        const currentDate: Date = new Date(date);
        currentDate.setMonth(currentDate.getMonth() - 1);
        if (currentDate >= startDateObj) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  public isValueInPickedValues(value: any) {
    return this.pickedValues.some((val) => val === value);
  }

  public deleteFilter(index: number) {
    this.modelFilters.splice(index, 1);
  }

  public deleteTempFilter(index: number) {
    this.tempModelFilters.splice(index, 1);
  }

  public toggle() {
    const allPresentInPickedValues = this.columnInformation.column_values.every((obj) =>
      this.pickedValues.includes(obj.value),
    );
    if (!allPresentInPickedValues) {
      this.columnInformation.column_values.forEach((obj) => {
        if (!this.pickedValues.includes(obj.value)) {
          this.pickedValues.push(obj.value);
        }
      });
    } else {
      this.pickedValues = [];
    }
  }

  public expandRow(index) {
    console.log("index", index);
    if (this.expandedRow === index) {
      this.expandedRow = null;
    } else {
      this.expandedRow = index;
    }
  }

  public closeFilterDialog() {
    this.dialogFilter = false;
    this.pickedModelAndLabels = { model: -1, labels: [] as number[], label_container: -1 };
    this.d6 = 0;
    this.recommendationFilter = -1;
    this.filterDataset = true;
    this.filterError = null;
    this.filterType = "must";
    this.columnInformation = {} as IColumnInformation;
    this.pickedValues = [];
    this.showAll = false;
    this.endDate = "";
    this.startDate = "";
    this.tempModelFilters = [];
    this.expandedRowModel = null;
  }

  public goBackFilter() {
    if (this.filterDataset) {
      if (this.d6 === 1) {
        this.d6 = 0;
      } else if (this.d6 === 2) {
        this.d6 = 1;
        this.pickedValues = [];
        this.startDate = "";
        this.endDate = "";
      } else if (this.d6 === 3) {
        this.totalItems = 0;
        this.d6 = 2;
        this.showAll = false;
      }
    } else {
      if (this.d6 === 1) {
        this.d6 = 0;
        this.pickedModelAndLabels = { model: -1, labels: [] as number[], label_container: -1 };
      } else if (this.d6 === 2) {
        this.tempModelFilters = [];
        this.expandedRowModel = null;
        this.d6 = 1;
      } else if (this.d6 === 3) {
        this.totalItems = 0;
        this.d6 = 2;
        this.showAll = false;
      }
    }
  }

  public nextStepFilter() {
    console.log(this.d6);
    if (this.filterDataset) {
      if (this.d6 === 0) {
        this.d6 = 1;
      } else if (this.d6 === 1) {
        this.filterColumnValues();
      } else if (this.d6 === 2) {
        this.getFilterCount();
      } else if (this.d6 === 3) {
        this.addModelFilter();
        this.closeFilterDialog();
      }
    } else {
      if (this.d6 === 0) {
        this.d6 = 1;
      } else if (this.d6 === 1) {
        this.d6 = 2;
        this.createTempModelFilters();
      } else if (this.d6 === 2) {
        this.getFilterCount(false);
      } else if (this.d6 === 3) {
        this.modelFilters = this.tempModelFilters.slice();
        this.closeFilterDialog();
      }
    }
  }

  public getFilterColumnName(id) {
    if (this.rows.length > 0) {
      const columnIndex = `column_${id}`;
      const row = this.rows[0];
      if (row.hasOwnProperty(columnIndex)) {
        return row[columnIndex];
      }
    } else {
      return "None";
    }
  }

  public addModelFilter() {
    if (this.filterType !== "timestamp") {
      const filter: IModelFilterString = {
        column_id: this.recommendationFilter,
        store_column_name: this.columnInformation.store_column_name,
        filter_values: this.pickedValues,
        dtype: this.columnInformation.dtype,
        filter_type: this.filterType,
      };
      this.modelFilters.push(filter);
    } else {
      const filter: IModelFilterTimestamp = {
        column_id: this.recommendationFilter,
        store_column_name: this.columnInformation.store_column_name,
        dtype: this.columnInformation.dtype,
        filter_type: this.filterType,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      this.modelFilters.push(filter);
    }
  }

  public async selectAlgorithm(item) {
    this.chosenAlgorithm = item.title;

    if (this.datasetName !== "") {
      this.e6 = 5;
      return;
    }

    if (this.newDataset) {
      this.createDataset(JSON.stringify(this.datasetColumns[this.chosenAlgorithm]));
    }

    this.nextStep();
  }

  public async createDataset(columns: string) {
    this.loading = true;
    this.uploadError = null;
    // this.dialog = false;

    const datasetInfo: IDatasetCreate = {
      name: this.name,
      description: this.description,
      filename: columns,
      text_column_id: 0, // not needed here
    };

    await api
      .createDataset(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        datasetInfo,
        false,
        false,
        true,
      )
      .then((r) => {
        this.loading = false;
        this.name = "";
        this.description = "";
      })
      .catch((uploadError) => {
        console.log("UploadError", uploadError.response);
        this.loading = false;
        this.uploadError = uploadError.response;
      });
  }

  public newDatasetSetup() {
    this.newDataset = true;
    this.toggleNewDataset = true;
  }
  public async getFilterCount(datasetFilter = true) {
    this.loadingButton = true;
    this.filterError = null;
    let allFilters = this.modelFilters.slice();
    if (datasetFilter === true) {
      if (this.filterType !== "timestamp") {
        const filter: IModelFilterString = {
          store_column_name: this.columnInformation.store_column_name,
          column_id: this.recommendationFilter,
          filter_values: this.pickedValues,
          dtype: this.columnInformation.dtype,
          filter_type: this.filterType,
        };

        allFilters.push(filter);
      } else {
        const filter: IModelFilterTimestamp = {
          store_column_name: this.columnInformation.store_column_name,
          column_id: this.recommendationFilter,
          dtype: this.columnInformation.dtype,
          filter_type: this.filterType,
          start_date: this.startDate,
          end_date: this.endDate,
        };

        allFilters.push(filter);
      }
    } else {
      allFilters = this.tempModelFilters.slice();
    }

    await api
      .getFilterCount(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.chosenDatasetId,
        allFilters,
      )
      .then((r) => {
        this.loadingButton = false;
        this.totalItems = r.data.total_items;
        this.d6 = 3;
      })
      .catch((error) => {
        console.log("filterError", error.response);
        this.loadingButton = false;
        this.filterError = error.response;
      });
  }

  public async filterColumnValues() {
    this.loadingButton = true;
    this.filterError = null;
    await api
      .filterColumnValues(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.chosenDatasetId,
        this.recommendationFilter,
        this.modelFilters,
      )
      .then((r) => {
        this.columnInformation = r.data;
        this.loadingButton = false;
        this.d6 = 2;
        if (this.columnInformation.dtype === "timestamp") {
          this.filterType = "timestamp";
        } else {
          this.filterType = "must";
        }
        console.log("kolla", r.data, typeof this.columnInformation);
      })
      .catch((error) => {
        console.log("filterError", error.response);
        this.loadingButton = false;
        this.filterError = error.response;
        this.columnInformation = {} as IColumnInformation;
      });
  }

  public async mounted() {
    await dispatchGetModels(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
    await dispatchGetDatasets(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
    // store params if we were redirected
    if (this.$route.query.connect === "true") {
      const tmp: any = this.$route.query.model_id;
      this.modelConnect = parseInt(tmp, 10);
      console.log("checking model connect");

      this.connectDataset = true;
      this.e6 = 1;
      this.ownData = true;
      await this.getAvailableDatasets();
    }

    if (this.$route.query.redirected === "true") {
      if (this.$route.query.dataset_id !== null && this.$route.query.trustpilot === "false") {
        this.chooseDataset(this.$route.query.dataset_id);
        this.e6 = 2;
      }
      this.ownData = true;
    }
  }

  public async getAvailableDatasets() {
    await api
      .getAvailableDatasets(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.modelConnect,
      )
      .then((r) => {
        this.availableDatasets = r.data;
        console.log("kolla", this.availableDatasets);
      })
      .catch((error) => {
        console.log("UploadError", error.response);
      });
  }

  get availablePrivateDatasets() {
    const unsortedAvailableDatasets = this.availableDatasets.filter(
      (dataset) => dataset.public !== true,
    );
    return unsortedAvailableDatasets.slice().sort((a, b) => b.id - a.id);
  }

  get datasets() {
    return readDatasets(this.$store);
  }

  get privateDatasets() {
    const pDatasets = this.datasets.filter((dataset) => dataset.public !== true);
    const sortedPDatasets = pDatasets.slice().sort((a, b) => b.id - a.id);
    return sortedPDatasets;
  }

  public async goBack() {
    if (this.e6 === 3) {
      // go back to step 2 (pick columns)
      this.e6 = 2;
    } else if (this.e6 === 2) {
      // go back to step 1 (pick dataset)
      this.chosenDatasetId = -1;
      this.columns = [];
      this.rows = [];
      this.rowsToShow = this.rows;
      this.name = "";
      this.recommendationLabels = -1;
      this.toggleMulti = false;
      this.valSet = 10;
      this.e6 = 1;
    } else if (this.e6 === 4) {
      this.e6 = 3;
      this.labelPreview = [];
    } else {
      this.e6 = this.e6 - 1;
    }
  }

  public selectConversationDataset(datasetId) {
    this.chosenDatasetId = datasetId !== this.chosenDatasetId ? datasetId : -1;
    this.loadingButton = false;
  }

  public async chooseDataset(datasetId) {
    this.loadingButton = true;
    await api
      .getDatasetPreview(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        datasetId,
      )
      .then((r) => {
        this.loadingButton = false;
        this.chosenDatasetId = datasetId;
        this.uploadError = null;
        this.columns = r.data.columns;
        this.rows = r.data.rows;
        // this.headDialog = true;
        this.rowsToShow = this.rows;

        // THIS IS A TEMPORARY SOLUTION TO SKIP THE ALGORITHM SELECTION STEP
        this.nextStep();
        this.chosenAlgorithm = "ORPO";
        this.nextStep();

        this.prefillName();
        this.getDatasetConnectedModels();
      })
      .catch((error) => {
        console.log("error when getting chosen dataset", error);
        this.loadingButton = false;
      });
  }

  public async prefillName() {
    const chosenDataset = this.datasets.filter((dataset) => dataset.id === this.chosenDatasetId);

    try {
      this.name = "";
    } catch {
      console.log("something went wrong when prenaming model");
    }
  }

  public highlightFilter(key, value) {
    let newColumn = this.recommendationFilter;

    if (Number(key) || Number(key) === 0) {
      if (
        !this.isThisTextColumn(key) &&
        this.modelFilters.filter((filter) => filter.column_id === key).length === 0
      ) {
        newColumn = Number(key);
      }
    }

    if (this.recommendationFilter === newColumn) {
      this.recommendationFilter = -1;
    } else {
      this.recommendationFilter = newColumn;
    }
  }

  public toggleMultiFilter() {
    if (this.recommendationLabels !== -1) {
      const columnName = `column_${this.recommendationLabels}`;

      // Initialize a counter
      let count = 0;

      // Loop through each row in the rows array
      for (const row of this.rows) {
        // Get the value from the column of interest
        const value = row[columnName];

        // Check if the value is a string and meets the criteria
        if (
          typeof value === "string" &&
          (value.includes("|") || (value.startsWith("[") && value.endsWith("]")))
        ) {
          count++;
        }
      }
      if (count > 8) {
        this.toggleMulti = true;
      } else {
        this.toggleMulti = false;
      }
    } else {
      this.toggleMulti = false;
    }
  }

  public highlight(key, value) {
    let newColumn = this.recommendationLabels;
    if (Number(key) || Number(key) === 0) {
      if (!this.isThisTextColumn(key)) {
        newColumn = Number(key);
      }
    }
    if (this.recommendationLabels === newColumn) {
      this.recommendationLabels = -1;
    } else {
      this.recommendationLabels = newColumn;
    }
    this.toggleMultiFilter();
  }

  get previewRows() {
    return this.rows.slice(1);
  }

  get activeRecommendationLabels() {
    if (this.recommendationLabels > -1) {
      return this.columns[this.recommendationLabels].value;
    } else {
      return "None";
    }
  }

  get activeRecommendationFilter() {
    if (this.recommendationFilter > -1) {
      return this.columns[this.recommendationFilter].value;
    } else {
      return "None";
    }
  }

  get tableDataHeader() {
    const rows: any[] = [];
    this.columns.forEach((column, key) => {
      const text = "";
      rows.push({
        text: this.rows[0][column.value] + text,
        value: column.value,
        align: "left",
      });
    });

    return rows;
  }

  get models() {
    return readModels(this.$store);
  }

  get nameExists(): boolean {
    return this.models.filter((model) => model.name === this.name).length > 0;
  }

  get nameErrors(): string[] {
    if (this.nameExists) {
      return ["This name does already exist"];
    }
    if (this.name.length && (this.name.length < 1 || this.name.length > 30)) {
      return ["Please provide a name between 1 and 30 characters long"];
    }
    return [];
  }

  get datasetNameExists(): boolean {
    if (this.datasets?.length) {
      return this.datasets.filter((dataset) => dataset.name === this.name).length > 0;
    } else {
      return false;
    }
  }

  get isClean(): boolean {
    if (this.name.length < 1 || this.name.length > 40) {
      return false;
    }
    if (this.nameExists) {
      return false;
    }

    return true;
  }

  get datasetNameIsClean(): boolean {
    if (this.datasetName.length < 1 || this.datasetName.length > 40) {
      return false;
    }
    if (this.nameExists) {
      return false;
    }

    return true;
  }

  public isThisTextColumn(key: number) {
    const datasetWithMetaData = this.datasets.filter(
      (dataset) => dataset.id === this.chosenDatasetId,
    )[0];
    const keyString = String(key); // Convert the key to a string

    if (datasetWithMetaData.meta_data.columns.hasOwnProperty(keyString)) {
      const column = datasetWithMetaData.meta_data.columns[keyString];

      if (column.hasOwnProperty("special_column") && column.special_column === "text") {
        console.log(`Column ${keyString} has special_column = "text"`);
        return true;
      }
    }
    return false;
  }

  get token() {
    return readToken(this.$store);
  }

  public async nextStep() {
    this.e6 = this.e6 + 1;

    console.log("e6 end", this.e6);
  }

  public async getLabelPreview() {
    this.loadingButton = true;
    this.previewError = null;

    let filter: any[] | boolean = false;
    if (this.modelFilters.length !== 0) {
      filter = this.modelFilters.slice();
    }

    await api
      .labelPreview(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.chosenDatasetId,
        this.recommendationLabels,
        this.toggleMulti,
        filter,
      )
      .then((r) => {
        this.loadingButton = false;
        this.previewError = null;
        this.labelPreview = r.data;
      })
      .catch((uploadError) => {
        console.log("UploadError", uploadError.response);
        this.loadingButton = false;
        this.previewError = uploadError.response;
      });
  }

  public async createModel() {
    console.log("createModel", this.connectDataset, this.recommendationLabels !== -1);

    // // if we we are creating a new dataset from scratch
    //   if (this.datasetName !== "") {
    //     await this.createModelAndDataset();
    //   }

    await this.createModelFromUpload();
  }

  public async createModelFromUpload() {
    this.loading = true;
    const model: IModelCreate = {
      name: this.name,
      description: this.description,
    };

    let filter: any[] | boolean = false;
    if (this.modelFilters.length !== 0) {
      filter = this.modelFilters.slice();
    }

    await api
      .createGenerativeModelFromUpload(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.chosenDatasetId,
        this.chosenAlgorithm,
        model,
        filter,
      )
      .then((r) => {
        this.recommendationLabels = -1;
        this.loading = false;
        this.uploadError = null;
        this.name = "";
        this.description = "";
        this.$router.push(`/main/${this.$router.currentRoute.params.workspaceid}/generative/view`);
      })
      .catch((uploadError) => {
        console.log("UploadError", uploadError.response);
        this.loading = false;
        this.uploadError = uploadError.response;
      });
  }

  public async createModelFromUploadWithLabels() {
    this.uploadError = null;
    this.loading = true;
    const model: IModelCreate = {
      name: this.name,
      description: this.description,
    };

    let filter: any[] | boolean = false;
    if (this.modelFilters.length !== 0) {
      filter = this.modelFilters.slice();
    }

    let labelManager: any = { actions: [] };
    this.labelPreview.forEach((a) => {
      if (!a.include) {
        labelManager.actions.push({
          action: "exclude",
          label_name: a.name,
        });
      }
    });

    await api
      .createModelFromUploadWithLabels(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.chosenDatasetId,
        this.recommendationLabels,
        this.toggleMulti,
        this.valSet,
        filter,
        model,
        labelManager,
      )
      .then((r) => {
        this.recommendationLabels = -1;
        this.loading = false;
        this.uploadError = null;
        this.name = "";
        this.description = "";
        this.$router.push(
          `/main/${this.$router.currentRoute.params.workspaceid}/classification/view`,
        );
      })
      .catch((uploadError) => {
        console.log("UploadError", uploadError.response);
        this.loading = false;
        this.uploadError = uploadError.response;
      });
  }
}
