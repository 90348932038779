
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { IDatasetUpdate, IDatasetUpdatePrompts, IDatasetUpdateRetention } from "@/interfaces";
import { readDatasets, readDataset, readInferencePrompts } from "@/store/dataset/getters";
import DropdownMenu from "@/components/GenerativeModelSelection.vue";
import {
  dispatchUpdateDataset,
  dispatchGetDatasets,
  dispatchGetInferencePrompts,
  dispatchUpdateDatasetPrompts,
  dispatchDeleteInferencePrompt,
  dispatchUpdateDatasetRetention,
} from "@/store/dataset/actions";
import { api } from "@/api";
import { readUserProfile, readHasAdminAccess, readToken } from "@/store/main/getters";

@Component({
  components: {
    DropdownMenu,
  },
})
export default class UpdateDataset extends Vue {
  public id: number = 1;
  public datasetName: string = "";
  public datasetDescription: string = "";
  public summaryPrompt: string = "";
  public clusterPrompt: string = "";
  public dialogLabelContainer: boolean = true;
  public loading: boolean = false;
  public tab: any = null;
  public datasetNameError: string = "";
  public selectedMetadataConvo: any[] = [];
  public selectedMetadataCsat: any[] = [];
  public selectedMetadataDate: any[] = [];
  public metadataOptions: any[] = [];
  public metaDataError: any = null;
  public loadingButton: boolean = false;
  public useRetention: boolean = false;
  public defaultPrompts: any = {};
  public selectedDsat: number[] = [1, 2];
  public ModelSelectError: string = "";
  public loadingModel: boolean = false;
  public generativeModels: any[] = [{ name: "Default model", description: "", status: "trained" }];
  public selectedGenerativeModels: any = {};
  public retentionDays: number = -1;
  public retentionError: any = null;
  public dialogRetention: boolean = false;

  get isAdmin() {
    return readHasAdminAccess;
  }

  get selectedModelName(): (key: string) => string {
    return (key: string) => {
      if (!this.selectedGenerativeModels[key]) {
        // Use Vue.set to add a new reactive property
        this.$set(this.selectedGenerativeModels, key, this.generativeModels[0]);
      }
      return this.selectedGenerativeModels[key].name;
    };
  }

  public setDsat() {
    this.loading = true;
    api
      .setDsat(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        this.selectedDsat,
      )
      .then((r) => {
        this.loading = false;
      })
      .catch((error) => {
        console.log("error when setting dsat", error);
        this.loading = false;
      });
  }

  public hasProperty(specialColumnValue: string) {
    let hasValue = false;

    for (const key in this.dataset!.meta_data.columns) {
      if (this.dataset!.meta_data.columns.hasOwnProperty(key)) {
        const column = this.dataset!.meta_data.columns[key];
        if (column.special_column === specialColumnValue) {
          hasValue = true;
          break;
        }
      }
    }
    return hasValue;
  }

  public whatIsDsat() {
    for (const key in this.dataset!.meta_data) {
      if (this.dataset!.meta_data.hasOwnProperty("settings")) {
        console.log("has settings");
        if (this.dataset!.meta_data.settings.hasOwnProperty("dsat")) {
          console.log("has dsat", this.dataset!.meta_data.settings.dsat);
          this.selectedDsat = this.dataset!.meta_data.settings.dsat;
        }
      }
    }
  }

  public setSelectedModel(item: any) {
    let key = item.menuId;
    let model = item.item;

    this.$set(this.selectedGenerativeModels, key, model);
  }

  public async getGenerativeModels() {
    this.loadingModel = true;

    await api
      .getGenerativeModels(this.token, parseInt(this.$router.currentRoute.params.workspaceid, 10))
      .then((r) => {
        // extend the list with r.data
        this.generativeModels = this.generativeModels.concat(r.data);

        this.loadingModel = false;

        //
      })
      .catch((error) => {
        console.log("error when getting generative models", error);
      });
  }

  get hasCsat() {
    return this.hasProperty("csat");
  }

  public async mounted() {
    console.log("Getting inferencePrompts");
    await dispatchGetInferencePrompts(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      datasetId: parseInt(this.$router.currentRoute.params.id, 10),
    });
    this.whatIsDsat();
    this.datasetName = this.dataset ? this.dataset.name : "";
    this.datasetDescription = this.dataset ? this.dataset.description : "";
    this.createDatasetColumns();
    await this.getGenerativeModels();
    this.showDefaultPromptsIfEmpty();
    if (
      this.dataset!.retention_days !== null &&
      this.dataset!.retention_days !== undefined &&
      this.dataset!.retention_days > 0
    ) {
      this.retentionDays = this.dataset!.retention_days;
      this.useRetention = true;
    }
  }

  public getGenModelFromId(id: number) {
    return this.generativeModels.find((model) => model.id === id);
  }
  public async showDefaultPromptsIfEmpty() {
    this.summaryPrompt = this.dataset ? this.dataset.summary_prompt : "";
    this.clusterPrompt = this.dataset ? this.dataset.cluster_prompt : "";
    const summaryModel = this.dataset ? this.dataset.summary_model : null;
    const promptModel = this.dataset ? this.dataset.cluster_model : null;

    if (summaryModel) {
      this.selectedGenerativeModels["summary-model"] = this.getGenModelFromId(summaryModel);
    }

    if (promptModel) {
      this.selectedGenerativeModels["prompt-model"] = this.getGenModelFromId(promptModel);
    }

    if (
      this.summaryPrompt === null ||
      this.summaryPrompt.trim() === "" ||
      this.clusterPrompt === null ||
      this.clusterPrompt.trim() === ""
    ) {
      await this.getDefaultPrompts();
      if (this.summaryPrompt === null || this.summaryPrompt == "") {
        this.summaryPrompt = this.defaultPrompts.summary_prompt;
      }
      if (this.clusterPrompt === null || this.clusterPrompt == "") {
        this.clusterPrompt = this.defaultPrompts.cluster_prompt;
      }
    }
  }

  public async toggleSound() {
    this.loading = true;
    await api
      .toggleSound(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
      )
      .then((r) => {
        this.dataset!.sound = !this.dataset!.sound;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
      });
  }

  get woot() {
    return "woot";
  }

  get datasets() {
    return readDatasets(this.$store);
  }

  get inferencePrompts() {
    return readInferencePrompts(this.$store);
  }

  get dataset() {
    return readDataset(this.$store)(+this.$router.currentRoute.params.id);
  }
  // ==================DATASET NAME===========
  get datasetNameExists(): boolean {
    if (this.datasetName === this.dataset!.name) {
      return false;
    }
    return this.datasets.filter((dataset) => dataset.name === this.datasetName).length > 0;
  }

  public async getDefaultPrompts() {
    await api
      .getDefaultPrompts(this.token, parseInt(this.$router.currentRoute.params.workspaceid, 10))
      .then((r) => {
        console.log("RESPONSE", r.data);
        this.defaultPrompts = r.data;
      })
      .catch((error) => {
        console.log("error when getting default prompts", error);
      });
  }

  public async setMetaData(object: any, type: string = "conversation") {
    this.metaDataError = null;
    this.loadingButton = true;
    let metaData: any;
    if (type === "conversation") {
      metaData = {
        conversation: parseInt(object.key, 10),
      };
    } else if (type === "csat") {
      metaData = {
        csat: parseInt(object.key, 10),
      };
    } else if (type === "date") {
      metaData = {
        date: parseInt(object.key, 10),
      };
    }
    console.log(metaData);
    await api
      .setMetaData(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        metaData,
      )
      .then((r) => {
        this.loadingButton = false;
      })
      .catch((error) => {
        dispatchGetDatasets(this.$store, {
          id: +this.$router.currentRoute.params.workspaceid,
        });
        console.log("error when setting metadata", error);
        this.metaDataError = error.response;
        this.loadingButton = false;
      });

    await dispatchGetDatasets(this.$store, {
      id: +this.$router.currentRoute.params.workspaceid,
    });
    await this.createDatasetColumns();
  }

  get datasetNameErrors(): string[] {
    if (this.datasetNameExists) {
      return ["This name does already exist"];
    }
    if (this.datasetName.length && (this.datasetName.length < 1 || this.datasetName.length > 40)) {
      return ["Please provide a name between 1 and 40 characters long"];
    }
    return [];
  }

  get datasetIsClean(): boolean {
    if (this.datasetName.length < 1 || this.datasetName.length > 40) {
      return false;
    }
    if (this.datasetNameExists) {
      return false;
    }

    return true;
  }

  get token() {
    return readToken(this.$store);
  }

  get hasConversationProperty() {
    if (this.dataset && this.dataset.meta_data && this.dataset.meta_data.columns) {
      // Convert the object's values to an array if it's not already an array
      const columnsArray = Array.isArray(this.dataset.meta_data.columns)
        ? this.dataset.meta_data.columns
        : Object.values(this.dataset.meta_data.columns);

      // Now use .find on the array
      const conversationColumn = columnsArray.find(
        (column) => column.special_column === "conversation",
      );

      if (conversationColumn) {
        return conversationColumn;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get hasCsatProperty() {
    if (this.dataset && this.dataset.meta_data && this.dataset.meta_data.columns) {
      // Convert the object's values to an array if it's not already an array
      const columnsArray = Array.isArray(this.dataset.meta_data.columns)
        ? this.dataset.meta_data.columns
        : Object.values(this.dataset.meta_data.columns);

      // Now use .find on the array
      const csatColumn = columnsArray.find((column) => column.special_column === "csat");

      if (csatColumn) {
        return csatColumn;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get hasDateProperty() {
    if (this.dataset && this.dataset.meta_data && this.dataset.meta_data.columns) {
      // Convert the object's values to an array if it's not already an array
      const columnsArray = Array.isArray(this.dataset.meta_data.columns)
        ? this.dataset.meta_data.columns
        : Object.values(this.dataset.meta_data.columns);

      // Now use .find on the array
      const dateColumn = columnsArray.find((column) => column.special_column === "date");

      if (dateColumn) {
        return dateColumn;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public createDatasetColumns() {
    if (this.dataset && this.dataset.meta_data && this.dataset.meta_data.columns) {
      this.metadataOptions = [];
      Object.keys(this.dataset.meta_data.columns).forEach((columnKey) => {
        // Skip the key "UniqueID"
        if (this.dataset!.meta_data.columns[columnKey].name !== "UniqueID") {
          this.metadataOptions.push({
            key: columnKey,
            ...this.dataset!.meta_data.columns[columnKey],
          });
        }
      });
    } else {
      this.metadataOptions = [];
    }
  }

  public updateDatasetName() {
    this.datasetNameError = "";
    const dataset: IDatasetUpdate = {
      name: this.datasetName,
      description: this.datasetDescription,
    };
    dispatchUpdateDataset(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      datasetId: this.dataset!.id,
      dataset,
    })
      .then((r) => {})
      .catch((err) => {
        this.datasetNameError = err.response;
      });
  }

  public updateRetentionDays() {
    if (!this.useRetention) {
      this.retentionDays = -1;
    }
    const datasetRetention: IDatasetUpdateRetention = {
      retention_days: this.retentionDays,
    };
    dispatchUpdateDatasetRetention(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      datasetId: this.dataset!.id,
      datasetRetention,
    })
      .then((r) => {
        this.dialogRetention = false;
      })
      .catch((err) => {
        this.retentionError = err.response;
      });
  }

  public updateDatasetPrompts() {
    this.datasetNameError = "";
    const datasetPrompts: IDatasetUpdatePrompts = {
      summary_prompt: this.summaryPrompt,
      summary_model: this.selectedGenerativeModels["summary-model"].id ?? null,
      cluster_prompt: this.clusterPrompt,
      cluster_model: this.selectedGenerativeModels["prompt-model"].id ?? null,
    };
    dispatchUpdateDatasetPrompts(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      datasetId: this.dataset!.id,
      datasetPrompts,
    })
      .then((r) => {})
      .catch((err) => {
        this.datasetNameError = err.response;
      });
  }

  public resetDatasetPrompts() {
    this.datasetNameError = "";
    const datasetPrompts: IDatasetUpdatePrompts = {
      summary_prompt: "",
      summary_model: null,
      cluster_prompt: "",
      cluster_model: null,
    };
    dispatchUpdateDatasetPrompts(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      datasetId: this.dataset!.id,
      datasetPrompts,
    })
      .then((r) => {
        this.showDefaultPromptsIfEmpty();
      })
      .catch((err) => {
        this.datasetNameError = err.response;
      });
  }
}
