
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { IGenerativeModel } from "@/interfaces";
import { readGenerativeModels } from "@/store/generative_model/getters";
import {
  dispatchGetGenerativeModels,
  dispatchCreateGenerativeModel,
  dispatchDeleteGenerativeModel,
  dispatchResetGenerativeModel,
} from "@/store/generative_model/actions";

import { readWorkspace } from "@/store/main/getters";

import FileDrop from "@/components/FileDrop.vue";
import ModelPageCard from "@/components/ModelPageCard.vue";

@Component({
  components: {
    FileDrop,
    ModelPageCard,
  },
})
export default class ModelView extends Vue {
  public dialog: boolean = false;
  public dialogRemove: boolean = false;
  public loading: boolean = false;
  public removeModelId: number = 0;
  public resetModelId: number = 0;
  public intervalId: number | null = null;
  public selectedColors: any[] = [];
  public modelColors: any[] = [];
  public privateModels: IGenerativeModel[] = [];
  public sortOption: string = "dateDesc";

  public filterModels() {
    if (this.selectedColors.length === 0) {
      this.selectedColors = this.modelColors.map((c) => c.value);
    }

    console.log(this.selectedColors);
    this.setPrivateModels();
  }

  public setModelColors() {
    let colors: any[] = [];

    this.models.forEach((m) => {
      colors.push(m.color);
    });
    const uniqueColors = new Set(colors);
    const colorArray = Array.from(uniqueColors);
    const colorArrayWithNames = colorArray.map((color) => {
      if (color === null) {
        return {
          name: "No color",
          value: null,
        };
      }
      return {
        name: color,
        value: color,
      };
    });
    this.modelColors = colorArrayWithNames;
  }

  public async mounted() {
    await this.fetchModels();
    this.intervalId = window.setInterval(() => {
      this.fetchModels();
    }, 15000);
    this.setModelColors();
    this.selectedColors = this.modelColors.map((c) => c.value);
    this.setPrivateModels();
  }

  public beforeRouteLeave(to: any, from: any, next: any) {
    if (this.intervalId !== null) {
      window.clearInterval(this.intervalId);
    }
    next();
  }

  public async fetchModels() {
    await dispatchGetGenerativeModels(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
  }

  public beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate in modeolsviewgenerative");
    dispatchGetGenerativeModels(this.$store, {
      id: +to.params.workspaceid,
    });
    next();
  }

  get isClassification() {
    return this.$router.currentRoute.path.includes("classification");
  }

  public formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  get workspace() {
    return readWorkspace(this.$store);
  }

  get models() {
    console.log(readGenerativeModels(this.$store));
    return readGenerativeModels(this.$store);
  }

  public removeModel(id, dialogRemove) {
    this.dialogRemove = dialogRemove;
    this.removeModelId = id;
  }

  public async deleteModel() {
    this.loading = true;
    console.log("removing model");
    await dispatchDeleteGenerativeModel(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      modelId: this.removeModelId,
    })
      .then((r) => {
        this.removeModelId = 0;
        this.dialogRemove = false;
        this.loading = false;
        this.setPrivateModels();
      })
      .catch((r) => {
        this.loading = false;
        this.dialogRemove = false;
        this.removeModelId = 0;
      });
  }

  public changeSort(sortOption: string) {
    if (sortOption !== this.sortOption) {
      this.sortOption = sortOption;
      this.setPrivateModels();
    }
  }

  public setPrivateModels() {
    // Filter out public models
    const pModels = this.models.filter((dataset) => dataset.public !== true);

    // Sort based on the sortOption
    let sortedPModels;
    if (this.sortOption === "dateDesc") {
      sortedPModels = pModels.slice().sort((a, b) => b.id - a.id);
    } else if (this.sortOption === "colorAsc") {
      sortedPModels = pModels.slice().sort((a, b) => {
        // Handle null values for color
        const colorA = a.color || "";
        const colorB = b.color || "";

        if (colorA === colorB) {
          return b.id - a.id; // Sort by id within the same color
        }
        return colorA.localeCompare(colorB); // Sort by color
      });
    }

    // Filter by selected colors
    const colorSorted = sortedPModels.filter((m) => this.selectedColors.includes(m.color));

    // Set the privateModels property
    this.privateModels = colorSorted;
  }
}
