
import { Component, Vue, Prop, Emit } from "vue-property-decorator";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

@Component
export default class DatasetCard extends Vue {
  @Prop({ default: null })
  public card!: any;
  @Prop({ default: false })
  public editable!: boolean;
  @Prop({ default: "main" })
  public buttons!: string;
  @Prop({ default: false })
  public loading!: boolean;
  @Prop({ default: null })
  public model!: any;
  @Prop({ default: false })
  public selected!: boolean;
  @Prop({ default: null })
  public selectedId!: any;
  public progress: boolean = false;

  // Editable, if we show delete menu etc
  // buttons. If we want it to look like main dataset page, connect or modelpage
  // Prop values: "main", "connect", "modelpage"
  // In the case of modelpage, we need the props model and loading as well!

  public async mounted() {}

  public formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  public removeDataset(id) {
    this.remove(id);
  }

  public disconnectChoice(id) {
    this.disconnect(id);
  }

  public chooseDataset(id) {
    // this.progress = !this.selectDataset ? true : false;
    this.chooseid(id);
  }

  public dateParse(ts) {
    dayjs.extend(tz);
    dayjs.extend(utc);
    const timeZone = dayjs.tz.guess();
    return dayjs.utc(ts).tz(timeZone).format("MMMM D, YYYY h:mm A");
  }

  get isClassification() {
    return this.$router.currentRoute.path.includes("classification");
  }

  get statusCheck() {
    // returns true if status indicates training/uploading etc
    if (this.model?.status === "ready") {
      if (this.isClassification && this.model?.label_containers[0].status === "ready") {
        return false;
      }
      return true;
    }
    return true;
  }

  @Emit()
  public remove(id) {}

  @Emit()
  public disconnect(id) {}

  @Emit()
  public chooseid(id) {}
}
