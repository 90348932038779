
import { api } from "@/api";
import { Component, Vue } from "vue-property-decorator";

import { readHasAdminAccess, readToken } from "@/store/main/getters";

import ConfusionMatrix from "@/components/ConfusionMatrix.vue";
import ItemPreview from "@/components/ItemPreview.vue";
import LabelBar from "@/components/LabelBar.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import RoundProgress from "@/components/RoundProgress.vue";
import { authUrl, customerApiUrl } from "@/env";
import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

@Component({
  components: {
    ItemPreview,
    ModelProgress,
    LabelBar,
    ConfusionMatrix,
    RoundProgress,
  },
})
export default class ApiView extends Vue {
  public loadingCredentials: boolean = false;
  public credentialsError: string = "";
  public bearerToken: string = "";
  public showToken: boolean = false;
  public id: number = 1;
  public metricThreshold: number = 0.0;
  public loading: boolean = false;
  public credentials: any = null;
  public clients: any = null;
  public models: any = null;
  public curlString: string = "";
  public generatedToken: any = null;
  public authString: string = authUrl + "/oauth2/token";
  public authUrl: string = authUrl;
  public clientId: string = "Generating...";
  public clientSecret: string = "Generating...";
  public copyIcon: string = "content_copy";

  public async getClients() {
    const res = await api.getClients(
      this.token,
      parseInt(this.$router.currentRoute.params.workspaceid, 10),
    );
    this.clients = res.data;
  }
  public parsedDate(ts) {
    dayjs.extend(tz);
    dayjs.extend(utc);
    const timeZone = dayjs.tz.guess();
    return dayjs.utc(ts).tz(timeZone).format("MMMM D, YYYY h:mm A");
  }

  public async getModels() {
    const res = await api.getModels(
      this.token,
      parseInt(this.$router.currentRoute.params.workspaceid, 10),
    );
    this.models = res.data;
  }

  public toggleIcon() {
    // Set icon to check
    this.copyIcon = "check";

    // Reset icon to copy after 1 second
    setTimeout(() => {
      this.copyIcon = "content_copy";
    }, 1000);
  }

  public copyText() {
    this.toggleIcon();
    const dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = this.bearerToken;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  public async mounted() {
    await this.getClients();
    await this.getModels();
  }
  public async createAPIToken() {
    const res = await api.createBearerToken(
      this.token,
      this.credentials.client_id,
      this.credentials.client_secret,
    );
    this.bearerToken = res.data;
    this.showToken = true;
  }

  public async newCredentials() {
    this.loadingCredentials = true;
    this.credentialsError = "";
    this.showToken = false;
    this.bearerToken = "";
    await api
      .createClientCredentials(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
      )
      .then((res) => {
        this.credentials = {
          client_name: res.data.client_name,
          client_id: res.data.client_id,
          client_secret: res.data.client_secret,
        };

        this.clientId = res.data.client_id;
        this.clientSecret = res.data.client_secret;
        this.getClients();
        this.loadingCredentials = false;
      })
      .catch((error) => {
        this.loadingCredentials = false;
        this.credentialsError = error.response;
        console.log("ERROR", this.credentialsError);
      });
  }

  public getInferenceCurl(modelId): string {
    const inferenceExample = '{"texts":["my first inference"]}';
    const inferenceURL = customerApiUrl + "/v2/models/" + modelId + "/inference";
    const result =
      'curl -X POST "' +
      inferenceURL +
      '" -H "accept: application/json" -H  "Authorization: Bearer <INSERT BEARER TOKEN HERE>' +
      '" -H  "Content-Type: application/json" -d ' +
      "'" +
      inferenceExample +
      "'";
    return result;
  }
  public goToDocumentation() {
    window.open("https://www.labelf.ai/docs/api/model-inference-api", "_blank");
  }

  public async revokeCredentials(clientName) {
    this.loadingCredentials = true;
    // If we remove 'clientName' that is 'active' in 'credentials', we need to remove it from the screen!

    if (this.credentials !== null && clientName === this.credentials.client_name) {
      this.credentials = null;
    }

    await api
      .revokeClientCredentials(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        clientName,
      )
      .then((res) => {
        this.clients = res.data;
        this.loadingCredentials = false;
      })
      .catch((error) => {
        this.loadingCredentials = false;
        console.log("ERROR", this.credentialsError);
      });
  }

  get isAdmin() {
    return readHasAdminAccess;
  }

  get token() {
    return readToken(this.$store);
  }
}
