
import { Component, Vue } from "vue-property-decorator";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { api } from "@/api";
import { appName, supersetUrl } from "@/env";
import axios from "axios";
import { table } from "console";
import { readWorkspace, readToken } from "@/store/main/getters";

@Component
export default class Editor extends Vue {
  get token() {
    return readToken(this.$store);
  }

  public async mounted() {
    console.log("Superset URL: ", supersetUrl);
  }
}
